import Attachment from '@/components/fileUploading/attachment';
import { linkFormat } from '@/helpers/linkFormat';
import { extractExternalId } from '@/pages/dashboard/commerce/components/utils';
import Tags from '@/pages/dashboard/commerce/form/lineItemForm/tags';
import { InvoiceLineItemsReadGQL } from '@/pages/dashboard/commerce/invoices/invoiceGQL';
import type { LineItemMultiOutput, Order, QueryLineItemsReadArgs } from '@/types/schema';
import { Chip, ListItem, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GraphqlProvider from '../../../../data/query/graphqlProvider';
import currencyFormat from '../../../../helpers/currencyFormat';

export default function RowPanel( { row, hidePrivateNote, hideTags }: {
	row: Order,
	hidePrivateNote?: boolean,
	hideTags?: boolean
} ) {
	const { t } = useTranslation();
	
	return (
		<GraphqlProvider<LineItemMultiOutput, QueryLineItemsReadArgs>
			queryKey='lineItems'
			query={InvoiceLineItemsReadGQL}
			subscription={{ LINE_ITEM: row.id }}
			variables={{
				options: {
					limit : 200,
					filter: {
						order: row.id,
					},
				},
			}}>
			{( { items: lineItems } ) => (
				<Paper sx={{ width: '100%', maxHeight: 400, overflow: 'overlay', border: 0 }}>
					{lineItems?.length ? lineItems.map( ( lineItem ) => {
						const nameHasPound = lineItem.name?.startsWith( '#' );
						let cloverOrderNumber;
						if ( nameHasPound ) {
							const orderNumber = extractExternalId( lineItem.name );
							cloverOrderNumber = orderNumber.length > 12 ? orderNumber : null;
							
						}
						const hasExternalTax = lineItem.prices?.find( ( price ) => price.metadata?.useTax && price.value > 0 );
						const hasTax = lineItem.tax && lineItem.tax > 0 || hasExternalTax || row.taxTotal > 0 && lineItem.orderTax;
						
						return (
							<ListItem
								key={lineItem.id}
								sx={{
									'px'                                : 1,
									'py'                                : .5,
									':not(.MuiListItem-root:last-child)': {
										borderBottom: 1,
										borderColor : 'divider',
									},
								}}>
								<ListItemText
									secondaryTypographyProps={{ component: 'div' }}
									primaryTypographyProps={{ component: 'div' }}
									primary={(
										<Stack direction='row' alignItems='center' spacing={1}>
											<Typography variant='subtitle2'>
												{cloverOrderNumber
													? linkFormat( lineItem.name, cloverOrderNumber, `https://${process.env.NEXT_PUBLIC_DEV
														? 'sandbox.dev'
														: 'www'}.clover.com/r/${cloverOrderNumber}` )
													: lineItem.name}
											</Typography>
											<Typography>
												{lineItem.quantity} {lineItem.unit}
											</Typography>
											<Typography>x</Typography>
											<Typography>
												{currencyFormat( lineItem.price )}
											</Typography>
											{hasTax && (
												<Tooltip
													title='This item is taxable'
													placement='right'>
													<Chip
														variant='alpha'
														color='default'
														label='Tax'
														sx={{
															fontStyle: 'italic',
															fontSize : '11px !important',
														}}
													/>
												</Tooltip>
											)}
										</Stack>
									)}
									secondary={lineItem?.prices?.filter( ( price ) => !price.metadata?.externalTax )
										?.map( ( fee ) => (
											<Typography key={fee.id}>
												{`${fee.name} ${fee.isPercent
													? `(${fee.value}%)`
													: ` - ${currencyFormat( fee.value )}`}`}
											</Typography>
										) )}
								/>
								<Stack direction='row' spacing={1} alignItems='center'>
									<Typography>
										{currencyFormat(
											lineItem.price
											* lineItem.quantity
											+ lineItem?.prices.reduce( ( values, fee ) => fee.value + values, 0 ),
										)}
									</Typography>
									<Tags lineItem={lineItem} company={row.company} hideTags={hideTags}/>
								</Stack>
							</ListItem>
						);
					} ) : (
						<Typography variant='subtitle2' p={.5} color='text.secondary'>
							{t( 'commerce:no-line-items' )}
						</Typography>
					)}
					{( row.metadata?.signature || row.deliveryStatusNote ) && (
						<Stack direction='row' alignItems='center' spacing={1} p={1}>
							{row.metadata?.signature && (
								<Stack spacing={1}>
									<Typography>{t( 'commerce:signed' )}</Typography>
									<Attachment
										whiteBg
										removeDownload
										src={row.metadata.signature}
										imageSX={{ width: 40, height: 40, objectFit: 'cover', mb: 0 }}
									/>
								</Stack>
							)}
							{row.deliveryStatusNote && (
								<Typography color='text.secondary' p={1} sx={{ whiteSpace: 'pre-line' }}>
									{t( 'commerce:note' )}: {row.deliveryStatusNote}
								</Typography>
							)}
						</Stack>
					)}
					{row.metadata?.privateNote && !hidePrivateNote && (
						<Typography color='text.secondary' p={1} sx={{ whiteSpace: 'pre-line' }}>
							{t( 'commerce:private-note' )}: {row.metadata.privateNote}
						</Typography>
					)}
				</Paper>
			)}
		</GraphqlProvider>
	
	);
}
